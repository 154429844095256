<template>
  <div class="mb-10">
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-row>
      <v-col cols="12" sm="12" md="10" lg="8" xl="6">
        <div class="d-flex justify-space-between align-center mb-4">
          <h2 class="mr-auto">Overview</h2>

          <v-btn
            class="mr-6 px-5 back-button"
            :to="{ name: 'new-business-details', params: { id: $attrs.id } }"
            :disabled="loading"
            text
          >
            <v-icon> {{ icons.back }} </v-icon>
            <span class="text-decoration-underline"> Go Back </span>
          </v-btn>

          <v-btn
            class="bg-primary-gradient primary next-button px-12"
            @click="saveAndFinish"
            :disabled="loading"
            depressed
            text
          >
            <span> Save & Finish </span>
            <v-icon> {{ icons.next }} </v-icon>
          </v-btn>
        </div>

        <div>
          <v-row>
            <v-col cols="12">
              <h4>Business Certification</h4>

              <v-row no-gutters>
                <v-col cols="12" sm="6">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-subtitle class="primary--text">
                        ABN:
                      </v-list-item-subtitle>
                      <v-list-item-title> {{ tradie.abn }} </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-subtitle class="primary--text">
                        Type of Business:
                      </v-list-item-subtitle>
                      <v-list-item-title>
                        <span v-if="tradie.business_type">
                          {{ tradie.business_type.type }}
                        </span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-subtitle class="primary--text">
                        Trade License:
                      </v-list-item-subtitle>
                      <v-list-item-title>
                        <span v-if="tradie.trade_license">
                          {{ tradie.trade_license.trade_license_name }}
                        </span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-subtitle class="primary--text">
                        License No.:
                      </v-list-item-subtitle>
                      <v-list-item-title>
                        {{ tradie.license_number }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <h4>Tradie Details</h4>

              <v-row no-gutters>
                <v-col cols="12" sm="6">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-subtitle class="primary--text">
                        Primary Contact Name:
                      </v-list-item-subtitle>
                      <v-list-item-title>
                        {{ tradie.primary_contact_name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-subtitle class="primary--text">
                        Email Address:
                      </v-list-item-subtitle>
                      <v-list-item-title>
                        <span v-if="tradie.user">
                          {{ tradie.user.email }}
                        </span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <h4>Bank Details</h4>

              <template v-if="tradie.user">
                <template v-if="tradie.user.bank_accounts">
                  <v-row
                    v-for="bank_account in tradie.user.bank_accounts"
                    :key="bank_account.id"
                    no-gutters
                  >
                    <v-col cols="12" sm="6">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-subtitle class="primary--text">
                            Bank Name:
                          </v-list-item-subtitle>
                          <v-list-item-title>
                            {{ bank_account.bank_name }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-subtitle class="primary--text">
                            Bank Account Name:
                          </v-list-item-subtitle>
                          <v-list-item-title>
                            {{ bank_account.bank_account_name }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-subtitle class="primary--text">
                            Bank Account Number:
                          </v-list-item-subtitle>
                          <v-list-item-title>
                            {{ bank_account.bank_account_number }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-subtitle class="primary--text">
                            BSB:
                          </v-list-item-subtitle>
                          <v-list-item-title>
                            {{ bank_account.bank_state_branch }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </template>
              </template>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <h4>Business Details</h4>

              <v-row no-gutters>
                <v-col cols="12" sm="6">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-subtitle class="primary--text">
                        Registered for GST:
                      </v-list-item-subtitle>
                      <v-list-item-title>
                        {{ tradie.is_apply_to_gst ? 'Yes' : 'No' }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-subtitle class="primary--text">
                        Business Type:
                      </v-list-item-subtitle>
                      <v-list-item-title>
                        <span v-if="tradie.business_area">
                          {{ tradie.business_area.business_area_name }}
                        </span>

                        <span class="white--text" v-else> -- </span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-subtitle class="primary--text">
                        Business Contact Number:
                      </v-list-item-subtitle>
                      <v-list-item-title>
                        {{ tradie.business_contact_number }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-subtitle class="primary--text">
                        Website
                      </v-list-item-subtitle>
                      <v-list-item-title>
                        <span v-if="tradie.website">
                          {{ tradie.website }}
                        </span>
                        <span class="white--text" v-else> -- </span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="mb-10">
            <v-col cols="12">
              <h4>Business Address</h4>

              <v-row no-gutters>
                <v-col cols="12" sm="6">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-subtitle class="primary--text">
                        Level/Floor/Unit/Flat:
                      </v-list-item-subtitle>
                      <v-list-item-title>
                        <template v-if="tradie.address">
                          <span v-if="tradie.address.floor_or_unit_number">
                            {{ tradie.address.floor_or_unit_number }}
                          </span>
                          <span class="white--text" v-else> -- </span>
                        </template>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-subtitle class="primary--text">
                        Street:
                      </v-list-item-subtitle>
                      <v-list-item-title>
                        <span v-if="tradie.address">
                          {{ tradie.address.street_number }}
                        </span>
                        <span class="white--text" v-else> -- </span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-subtitle class="primary--text">
                        Suburb:
                      </v-list-item-subtitle>
                      <v-list-item-title>
                        <template v-if="tradie.address">
                          <span v-if="tradie.address.suburb">
                            {{ tradie.address.suburb }}
                          </span>
                          <span class="white--text" v-else> -- </span>
                        </template>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-subtitle class="primary--text">
                            State:
                          </v-list-item-subtitle>
                          <v-list-item-title>
                            <template v-if="tradie.address">
                              <span v-if="tradie.address.state">
                                {{ tradie.address.state }}
                              </span>
                              <span class="white--text" v-else> -- </span>
                            </template>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-subtitle class="primary--text">
                            Zip Code:
                          </v-list-item-subtitle>
                          <v-list-item-title>
                            <template v-if="tradie.address">
                              <span v-if="tradie.address.postcode">
                                {{ tradie.address.postcode }}
                              </span>
                              <span class="white--text" v-else> -- </span>
                            </template>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <ConfirmDialog ref="confirm" cancelText="No" />
  </div>
</template>

<script>
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import Tradie from '@/models/Admin/Tradie'

import { mdiArrowRight, mdiArrowLeft } from '@mdi/js'
import { mapActions, mapMutations, mapState } from 'vuex'
import { get, has, isNil, isFunction } from 'lodash'

export default {
  name: 'NewTradieOverview',

  components: { ConfirmDialog },

  data() {
    return {
      perisistDialog: true,
      loading: false,
      icons: {
        back: mdiArrowLeft,
        next: mdiArrowRight,
      },
    }
  },

  computed: {
    ...mapState('newTradie', ['tradie']),

    withNewTradie() {
      return !!this.$attrs.id
    },

    isNotCompleted() {
      return !this.tradie.isCompleted
    },
  },

  created() {
    this.initValues()
  },

  methods: {
    ...mapActions('newTradie', ['getTradieDetails']),
    ...mapMutations('newTradie', ['setNewTradie']),

    async initValues() {
      if (this.withNewTradie && isNil(this.tradie.id)) {
        await this.getTradieDetails(this.$attrs.id)
      }
    },

    saveAndFinish(redirect) {
      if (this.loading) {
        return
      }

      this.loading = true

      const tradie = new Tradie({ id: this.$attrs.id, completed: true })

      tradie
        .save()
        .then(async ({ data }) => {
          await this.setNewTradie(data)

          if (isFunction(redirect)) {
            return redirect()
          }

          setTimeout(() => {
            this.$router.push({ name: 'tradies', query: { type: 'active' } })
          }, 900)
        })
        .finally(() => {
          this.loading = true
        })
    },
  },

  async beforeRouteLeave(to, from, next) {
    const isNewTradieRoute = get(to.meta, 'newTradie', false)

    if (isNewTradieRoute && this.isNotCompleted) {
      return next()
    }

    const hasDialog = has(this.$refs, 'confirm')

    if (hasDialog && this.isNotCompleted) {
      const confirm = await this.$refs.confirm.open(
        'Activate Tradie',
        'Do you want to keep changes on this page?'
      )

      return confirm ? this.saveAndFinish(next) : next()
    }

    next()
  },
}
</script>
